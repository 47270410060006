import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import Image1Logo from '../../Images/Porch.png'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
const BookAnAppointment = ({ setShowPopUp, locationsMain, setShowMenu, CitiePhone, CitiesData }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);
    const url = new URL(window.location.href);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Free Estimate',
        Date: new Date(new Date().getTime() + (0 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: CitiesData && CitiesData.City ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama',
        Zipcode: '',
        Note: ''
    });

    const FinalMessage = `
      SPG: ${url.searchParams.get('spg') == "g" ? "Google" : url.searchParams.get('spg') == "m" ? "Microsoft" : "Null"}
      Company name: BHO Garage Door
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Note: 
      ${CustInfo.Service}
      ${CustInfo.Note}
      The Job date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[0]}
      The Job end date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[1]}
      Job type: Garage Dooor
      Job source: Schedule Online
      `
    // 
    const location = useLocation()
    const navigate = useNavigate()
    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                {
                    CitiesData ?
                        navigate(`/thanks${location.search}&name=${CustInfo.FirstName}&service=${CustInfo.Service}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City}`)
                        :
                        navigate(`/thanks?name=${CustInfo.FirstName}&service=${CustInfo.Service}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City}`)
                }
                sentMessage()
            }, (error) => {
                console.log(error.text);
            });
    }
    const sentMessage = async () => {
        const res = await fetch('https://api.callrail.com/v3/a/317-421-447/text-messages.json', {
            method: 'POST',
            headers: {
                'Authorization': 'Token token=dd02c8e45b65e07d612f8a21a51d9e1d',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'customer_phone_number': `${CustInfo.PhoneNumber.replace(/-/g, '')}`,
                'tracking_number': `${CitiesData ? CitiePhone.replace(/-/g, '') : '8666459692'}`,
                'content': `
Hi ${CustInfo.FirstName} 😊,
Thank you for booking your Garage service with BHO Garage Door! Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} Between ${CustInfo.Time} At ${CustInfo.Address1 + ', ' + CustInfo.City}

Our Technician will reach you out 30Min in advance at the appointment day.

Best regards 🤝
BHO Garage Door.
                `,
                'company_id': '362413879'
            })
        })
        const data = await res.json()
    }


    return (
        <div className="BookAnAppointmentMainComponent2" >
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Schedule Garage Door Service Online | BHO Garage Doo | 866-605-4089</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Schedule Garage Door Service Online | BHO Garage Doo | 866-605-4089" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Schedule Garage Door Service Online | BHO Garage Doo | 866-605-4089" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />


                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhogaragedoor.com/book" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Easily schedule your professional Garage Door service online with BHO Garage Doo. Book inspections,s, and repairs at your convenience to keep your Garage Door safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Easily schedule your professional Garage Door service online with BHO Garage Doo. Book inspections,s, and repairs at your convenience to keep your Garage Door safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Schedule Garage Door Service Online | BHO Garage Doo | 866-605-4089" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Inspection, Garage Door Inspection, Fireplace Inspection, Fireplace, Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door Inspection, Fireplace Inspection, Fireplace, Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door services, Garage Door service, Garage Door Inspection, Fireplace Inspection, Fireplace, Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door Insulation, Garage Door Installation, Garage Door Liner Repair, Garage Door Pointing, Garage Door Rainp Installation, Garage Door Repair, Garage Door Crown Repair, Garage Door Restoration, Garage Door Flue Installation, Garage Door Flue Repair, ​Garage Door Construction, Garage Door Vent Installation, Garage Door Flashing, Flexible Garage Door Liner Installation, Garage Door Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Faq Page" data-react-helmet="true" />
            </Helmet>
            {/* Header */}
            {location.pathname != "/local" ?
                <Header setShowPopUp={setShowPopUp} setShowMenu={setShowMenu} CitiesData={CitiesData} CitiePhone={CitiePhone} />
                :
                null
            }
       {/* <div className="HomePageGoogleDuscount"> */}
       <div className="HomePageGoogleDuscount" >
                <a onClick={() => navigate(`/book${location.search}`)}>BOOK ONLINE</a>
                <h3>OR CALL US AT</h3>
                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
            </div>
            {/*  */}

            {/* BookAnAppointmentMainComponentTop */}
            <div className="BookAnAppointmentMainComponentTop">
                <div className="BookAnAppointmentMainComponentTopLeft">
                    <h1>SCHEDULE SERVICE ONLINE</h1>
                    <h2>Servicing <b>{CitiesData && CitiesData.Full ? CitiesData.Full.toUpperCase() : "Your Area"}</b> And Surrounding Areas</h2>
                    <h2>No extra charge for appointments on nights, weekends or holidays</h2>
                </div>
            </div>
            {/* BookAnAppointmentMainComponentTopMain */}
            <div className='BookAnAppointmentMainComponentTopMain'>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "3px solid #2970eb"
                    } : null}>Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "3px solid #2970eb"
                    } : null}>Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "3px solid #2970eb"
                    } : null}>Note </h4>
                </div>
                <div className="BookAnAppointmentMainPages">
                    {
                        Page1 ?
                            <div className="BookAnAppointmentMainComponentPage1">
                                <div className="BookAnAppointmentMainRightFullName">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Full Name <span>*</span></h4>
                                        <input
                                            defaultValue={CustInfo.FirstName}
                                            className='BookAnAppointmentMainRightBoxFirstName'
                                            onChange={(e) => {
                                                CustInfo.FirstName = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="text" />
                                    </div>
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Phone Number <span>*</span></h4>
                                        <input
                                            defaultValue={CustInfo.PhoneNumber}
                                            className='BookAnAppointmentMainRightBoxPhone'
                                            onChange={(e) => {
                                                CustInfo.PhoneNumber = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="tel" />
                                    </div>
                                    {/* <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div> */}
                                </div>
                                {/* <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div>
                            </div> */}
                                <div className="BookAnAppointmentMainRightDateAndTime">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Appointment Date <span>*</span></h4>
                                        <input
                                            defaultValue={CustInfo.Date}
                                            className='BookAnAppointmentMainRightBoxDate'
                                            onChange={(e) => {
                                                CustInfo.Date = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="date" />
                                    </div>
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Appointment Time <span>*</span></h4>
                                        <select
                                            defaultValue={CustInfo.Time}
                                            className='BookAnAppointmentMainRightBoxTime'
                                            onChange={(e) => {
                                                CustInfo.Time = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} >
                                            <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                            <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                            <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                            <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                            <option value="Ask for a Representative">Ask for a Representative</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainComponentButtons">
                                    <button onClick={() => {

                                        if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid #6f6f6f61"; }
                                        // if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid #6f6f6f61"; }
                                        if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid #6f6f6f61"; }
                                        if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid #6f6f6f61"; }
                                        if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid #6f6f6f61"; }

                                        if (
                                            CustInfo.FirstName &&
                                            // CustInfo.LastName &&
                                            CustInfo.PhoneNumber &&
                                            CustInfo.Service &&
                                            CustInfo.Date &&
                                            CustInfo.Time
                                        ) {
                                            setPage1(false)
                                            setPage2(true)
                                        } else {
                                            console.log('no')
                                        }
                                    }}>NEXT</button>
                                </div>
                            </div>
                            : null
                    }
                    {
                        Page2 ?
                            <div className="BookAnAppointmentMainComponentPage2">
                                <div className="BookAnAppointmentMainRightAddress1">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Address Line 1 <span>*</span></h4>
                                        <input
                                            className='BookAnAppointmentMainRightBoxAddress1'
                                            defaultValue={CustInfo.Address1}
                                            onChange={(e) => {
                                                CustInfo.Address1 = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="text" />
                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainRightAddress2">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Address Line 2 (Optional)</h4>
                                        <input
                                            defaultValue={CustInfo.Address2}
                                            onChange={(e) => {
                                                CustInfo.Address2 = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="text" />
                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainRightAddress3">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>City <span>*</span></h4>
                                        <input
                                            className='BookAnAppointmentMainRightBoxCity'
                                            defaultValue={CustInfo.City}
                                            onChange={(e) => {
                                                CustInfo.City = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="text" />
                                    </div>
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>State <span>*</span></h4>
                                        <select
                                            defaultValue={CitiesData && CitiesData.Full ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama'}
                                            onChange={(e) => {
                                                CustInfo.State = e.target.value
                                                setCustInfoUpdate(e => !e)
                                            }}>
                                            {/* <option value="" disabled selected>Select your State</option> */}
                                            <option value="Alabama">Alabama</option>
                                            <option value="Alaska">Alaska</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="Arkansas">Arkansas</option>
                                            <option value="California">California</option>
                                            <option value="Colorado">Colorado</option>
                                            <option value="Connecticut">Connecticut</option>
                                            <option value="Delaware">Delaware</option>
                                            <option value="District Of Columbia">District Of Columbia</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Gecomia">Gecomia</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Idaho">Idaho</option>
                                            <option value="Illinois">Illinois</option>
                                            <option value="Indiana">Indiana</option>
                                            <option value="Iowa">Iowa</option>
                                            <option value="Kansas">Kansas</option>
                                            <option value="Kentucky">Kentucky</option>
                                            <option value="Louisiana">Louisiana</option>
                                            <option value="Maine">Maine</option>
                                            <option value="Maryland">Maryland</option>
                                            <option value="Massachusetts">Massachusetts</option>
                                            <option value="Michigan">Michigan</option>
                                            <option value="Minnesota">Minnesota</option>
                                            <option value="Mississippi">Mississippi</option>
                                            <option value="Missouri">Missouri</option>
                                            <option value="Montana">Montana</option>
                                            <option value="Nebraska">Nebraska</option>
                                            <option value="Nevada">Nevada</option>
                                            <option value="New Hampshire">New Hampshire</option>
                                            <option value="New Jersey">New Jersey</option>
                                            <option value="New Mexico">New Mexico</option>
                                            <option value="New York">New York</option>
                                            <option value="Northrolina">Northrolina</option>
                                            <option value="North Dakota">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">Oklahoma</option>
                                            <option value="Oregon">Oregon</option>
                                            <option value="Pennsylvania">Pennsylvania</option>
                                            <option value="Rhode Island">Rhode Island</option>
                                            <option value="Southrolina">Southrolina</option>
                                            <option value="South Dakota">South Dakota</option>
                                            <option value="Tennessee">Tennessee</option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Vermont">Vermont</option>
                                            <option value="Virginia">Virginia</option>
                                            <option value="Washington">Washington</option>
                                            <option value="West Virginia">West Virginia</option>
                                            <option value="Wisconsin">Wisconsin</option>
                                            <option value="Wyoming">Wyoming</option>
                                        </select>
                                    </div>
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Zipcode <span>*</span></h4>
                                        <input
                                            className='BookAnAppointmentMainRightBoxZipcode'
                                            defaultValue={CustInfo.Zipcode}
                                            onChange={(e) => {
                                                CustInfo.Zipcode = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="tel" />
                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainComponentButtons">
                                    <button onClick={() => {

                                        setPage1(true)
                                        setPage2(false)
                                        setPage3(false)
                                    }}>PREVIOUS</button>
                                    <button onClick={() => {

                                        if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid #6f6f6f61"; }
                                        if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid #6f6f6f61"; }
                                        if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid #6f6f6f61"; }

                                        if (
                                            CustInfo.Address1 &&
                                            CustInfo.City &&
                                            CustInfo.Zipcode
                                        ) {
                                            setPage1(false)
                                            setPage2(false)
                                            setPage3(true)
                                        } else {
                                            console.log('no')
                                        }

                                    }}>NEXT</button>
                                </div>
                            </div>
                            : null
                    }
                    {
                        Page3 ?
                            <div className="BookAnAppointmentMainComponentPage3">

                                <div className="BookAnAppointmentMainRightNote">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Note (Optional)</h4>
                                        <textarea
                                            defaultValue={CustInfo.Note}
                                            onChange={(e) => {
                                                CustInfo.Note = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} type="text" />
                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainRightServices">
                                    <div className="BookAnAppointmentMainRightBox">
                                        <h4>Service Needed <span>*</span></h4>
                                        <select
                                            defaultValue={CustInfo.Service}
                                            onChange={(e) => {
                                                CustInfo.Service = e.target.value
                                                setCustInfoUpdate(e => !e)

                                            }} name="" id="">
                                            <option value="Free Estimate">Free Estimate</option>
                                            {/* <option value="Dryer Vent $79">Dryer Vent $79</option>
                                        <option value="Garage Door Free Inspection">Garage Door Free Inspection</option>
                                        <option value="Dryer Vent Free Inspection">Dryer Vent Free Inspection</option> */}
                                        </select>
                                        <h3>Technician will provide complimentary estimate on-site. Payment required only if/when service is done.</h3>

                                    </div>
                                </div>
                                <div className="BookAnAppointmentMainComponentButtons">
                                    {
                                        !FormSubmiting ?
                                            <button className='PREVIOUS' onClick={() => {
                                                setPage1(false)
                                                setPage2(true)
                                                setPage3(false)
                                            }}>PREVIOUS</button>
                                            :
                                            null
                                    }


                                    {
                                        !FormSubmiting ?
                                            <form className="contact-form" onSubmit={sendEmail}>
                                                <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                                {/* Cust Info */}
                                                <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                                <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                                <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                                <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                                <input type="hidden" name="Company_From" defaultValue={`BHO Garage Door`} />
                                                <input type="hidden" name="Company" defaultValue={`BHO Garage Door | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                                <input type="submit" value="SUBMIT" />
                                            </form>
                                            :
                                            null
                                    }

                                    {
                                        FormSubmiting ?
                                            <button class="buttonload">
                                                <i class="fa fa-refresh fa-spin"></i>Loading
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            : null
                    }
                </div>

                {/* <div className="BookAnAppointmentMainPagesCopun"> */}
                <div className="BookAnAppointmentMainPagesCopun" >
                    <div className="BookAnAppointmentMainPagesCopunBox">
                        <div className="BookAnAppointmentMainPagesCopunLeft">
                            <div></div>
                        </div>
                        <div className="BookAnAppointmentMainPagesCopunRight">
                            <h1>10% OFF And Free Estimate Applied</h1>
                            <h2>Enjoy BHO Garage Door's 10% Off and Free estimate for all services. Customers appreciate transparent, no-commitment evaluations, quality service, and expert advice.</h2>
                        </div>
                    </div>
                </div>
                {/*  */}

            </div>
            {/* BookAnAppointmentMainComponentEmergery */}
            {location.pathname == "/local" ? null : <div className="BookAnAppointmentMainComponentEmergery">

                <div className="BookAnAppointmentMainComponentRates">
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={YELP} alt="BHO Garage Doo Yelp Review" />
                        <img src={Angie} alt="BHO Garage Doo Angie Review" />
                        <img src={HomeAdvisor} alt="BHO Garage Doo HomeAdvisor Review" />
                        <img src={BBB} alt="BHO Garage Doo BBB Review" />
                        <img src={Google} alt="BHO Garage Doo Google Review" />
                        <img src={Porch} alt="BHO Garage Doo Porch Review" />
                        <img src={Trust} alt="BHO Garage Doo Trust Review" />
                    </div>
                    <h3>I've used BHO Garage Door {CitiesData ? " In " + CitiesData.City + " " : ""} for various repairs and maintenance, and I'm consistently impressed by their exceptional service. With over 20 years of industry experience, they are reliable and detail-focused, ensuring quality results. Their prompt response and commitment to safety and customer satisfaction make them my go-to choice for garage door needs.</h3>
                </div>
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                </div>
            </div>
            }
            {/* Footer */}
            {location.pathname != "/local" ?
                <Footer locationsMain={locationsMain} CitiesData={CitiesData} CitiePhone={CitiePhone} />
                :
                null
            }
        </div >
    );
}

export default BookAnAppointment;
